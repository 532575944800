<!-- eslint-disable max-len -->
<i18n lang="yaml">
  en:
    title: The thermostat <span class="color-green">who loves<br /> your budget and the planet</span>
    description: The Ween thermostat is the first smart assistant that heats your home <span class="type-weight-700">how you like it, only when you need it</span>. Start saving money and energy right now.
    product-name: Ween Thermostat
    economy: Estimate my savings
    discover: Discover Ween
  fr:
    title: Le thermostat <span class="color-green">qui fait du bien <br />à vos finances et à la planète</span>
    description: Le thermostat Ween est l'assistant intelligent qui chauffe votre maison <span class="type-weight-700">quand il faut, comme il faut</span>. Commencez à faire des économies dès maintenant.
    product-name: Thermostat Ween
    economy: Estimer mes économies
    discover: Découvrir Ween
</i18n>
<!-- eslint-enable max-len -->

<template>
  <section class="page-center type-align-center type-align-left--s grid | home-hero">
    <!-- <vue-image class="home-hero__circle-picture" src="/img/circle-picture-hero.jpg" background /> -->
    <div class="grid__row grid__row--end">
      <div
        v-if="!$mq.isOneOf('xxs xs')"
        class="grid__col-12--xxs grid__col-5--s grid__col-right--s space-pb-3 space-pb-0--s space-pt-4 space-pt-0--s"
      >
        <img
          :src="`/img/product-hero-${productImageIndex}-${lang}.jpg`"
          :alt="$t('product-name')"
          class="display-block space-mx-auto product-hero-img"
          @click="updateImage"
        />
      </div>
      <div class="grid__col-12--xxs grid__col-7--s space-pb-10--s space-pt-5--s">
        <!-- eslint-disable vue/no-v-html -->
        <h1
          class="type-weight-700 space-mb-4"
          :class="[ $mq.isOneOf('xxs xs') ? 'type-title-secondary space-mt-10' : 'type-title-primary' ]"
          v-html="$t('title')"
        />
        <p
          class="home-hero__description || space-mb-4 || type-weight-500"
          v-html="$t('description')"
        />
        <!-- eslint-enable vue/no-v-html -->
        <div class="space-mb-4--s justify-center || flex flex-wrap">
          <nuxt-link
            :to="localePath('economy')"
            class="btn btn--secondary btn--width-auto-xs space-mx-2--xs space-mx-0--s space-mr-4--s space-mb-2"
            :class="[ $mq.isOneOf('xxs xs') ? 'btn--fade btn--width-afull-xxs' : '' ]"
          >
            <!-- <vue-icon class="space-mr-2" name="icon-double-circle" width="26px" height="26px" /> -->
            {{ $t('economy') }}
          </nuxt-link>
          <nuxt-link
            v-on:click.native="gtm"
            :to="{ path: localePath('index'), hash: '#how-to' }"
            class="btn btn--primary btn--width-auto-xs space-mx-2--xs space-mx-0--s space-mb-2"
            :class="[ $mq.isOneOf('xxs xs') ? 'btn--width-afull-xxs' : '' ]"
          >{{ $t('discover') }}</nuxt-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueIcon from '~/components/Icon';
import VueImage from '~/components/Image';

export default {
  components: {
    VueIcon,
    VueImage,
  },
  data() {
    return {
      carouselImages: null,
      productImageIndex: 0,
      lang: this.$i18n.locale === 'fr' ? 'fr' : 'en',
    };
  },
  created() {
    this.carouselImages = setInterval(() => {
      this.updateImage();
    }, 3000);
  },
  destroyed() {
    clearInterval(this.carouselImages);
  },
  methods: {
    updateImage() {
      this.productImageIndex = (this.productImageIndex + 1) % 3; // 3 is the total nb of images
    },
    gtm() {
      this.$gtm.push({ event: 'gtm.cta.click.discover', info: '' });
    },
  },
};
</script>

<style lang="scss">
.btn--width-afull-xxs {
  width: 90%;
}

.product-hero-img {
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}

.home-hero__mark {
  font-size: 12px;

  @media #{media('s')} {
    @include font-size('btn');
    line-height: 1.1875em;
  }
}

.type-title-primary {
  font-size: 2.7em;
}

.home-hero__description {
  font-size: 22px;
  /* max-width: 488px; */

  @media #{media('s', 'max')} {
    font-size: 16px;
  }
}

.interline {
  margin-top: 5px;
}

.home-hero__circle-picture {
  position: absolute;
  top: 0;
  left: 50%;
  width: 150%;
  height: 0;
  padding-top: 150%;
  background-color: c('grey');
  border-radius: 50%;
  opacity: 0.8;
  transform: translate(-50%, -40%);

  @media #{media('xs')} {
    transform: translate(-50%, -45%);
  }

  @media #{media('s')} {
    top: unset;
    right: -50%;
    bottom: 10%;
    left: unset;
    width: 100%;
    padding-top: 100%;
    transform: unset;
  }

  @media #{media('m')} {
    right: -40%;
    bottom: 10%;
    width: 60rem;
    height: 60rem;
    padding-top: 0;
  }
}

.home-hero__price-tag {
  position: absolute;
  top: 30%;
  left: 66%;
  width: 64px;
  height: 64px;
  border-radius: 50%;

  @media #{media('xs', 'max')} {
    font-size: 0.777777778em;
  }

  @media #{media('xs')} {
    width: 90px;
    height: 90px;
  }
}

.home-hero__price-tag-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-hero__offer-tag {
  position: absolute;
  top: 30%;
  left: 20%;
  max-width: 225px;
  font-size: 14px;
  border-radius: 52px;

  @media #{md('m')} {
    top: 30.5%;
    left: 26%;
    max-width: 250px;
  }
}

.home-hero__conditions {
  margin-top: -1em;
  font-size: 14px;
  line-height: 24px;
  color: #888;
}

.home-hero__product {
  width: 100% !important;

  @media #{media('s')} {
    width: 65%;
  }
}

sub {
  font-size: smaller;
  vertical-align: sub;
}

.first-price {
  font-size: 16px;
}

.btn--fade {
  background-color: #eafde6;
}

.background-yellow-light {
  background-color: #ffa619;
}

.background-blue-light {
  background-color: #1a79db;
}
</style>
